import { useRoutes ,useLocation} from 'react-router-dom';
import { useEffect } from 'react';
import router from 'src/router';
import './App.css';
import 'src/locales/i18n';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { AuthGuard } from './content/auth/authGuard';
import { Helmet } from 'react-helmet-async';
import { SettingsProvider } from 'src/contexts/SettingContext';
import { LocalizationProvider } from 'src/locales';
import { HeaderProvider } from './contexts/HeaderContext';

function App() {
  const content = useRoutes(router);
  const location = useLocation();
  
  //Monitor user activity and logout after a period of inactivity
  // Timeout constants
  const TIMEOUT_DURATION = 10800000; // 3 hours
  // const TIMEOUT_DURATION = 60000; // 1 minute for testing purposes
  const lastActivityKey = 'lastActivity';

  const resetLogoutTimer = () => {
    localStorage.setItem(lastActivityKey, String(Date.now()));
  };

  const handleAutoLogout = (): void => {
    localStorage.removeItem('userName');
    localStorage.removeItem('admin-token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRoleId');
    localStorage.removeItem('userRoleGuid');
    localStorage.removeItem('parentCustomerId');
    localStorage.removeItem('lastActivity');
    localStorage.removeItem('counterId');
    localStorage.removeItem('workPeriodId');
    localStorage.removeItem('branchId');
    window.location.href = '/auth/sign-in';
  };

  useEffect(() => {
    const lastActivity = localStorage.getItem(lastActivityKey);
    const currentTime = Date.now();
    if ((lastActivity && currentTime - parseInt(lastActivity) > 0) && (currentTime - parseInt(lastActivity)) > TIMEOUT_DURATION) {
      if (location.pathname !== '/auth/sign-in' && location.pathname !== '/auth/sign-up') {
        handleAutoLogout();
      }
    } else {
      localStorage.setItem(lastActivityKey, String(currentTime));
    }

    const interval = setInterval(() => {
      const lastActivity = localStorage.getItem(lastActivityKey);
      const currentTime = Date.now();
      if (lastActivity && (currentTime - parseInt(lastActivity)) > TIMEOUT_DURATION) {
        handleAutoLogout();
      }
    }, 60000);

    // Add event listeners to reset timer on user activity
    const handleActivity = () => {
      resetLogoutTimer();
    };
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    // Cleanup interval and event listeners on component unmount
    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== 'pos') {
      const handleKeyPress = (event) => {
        const { keyCode, target } = event;
        const isEnterKey = keyCode === 13;
        const isInputOrListItem = target.nodeName === 'INPUT';
        if (isEnterKey && isInputOrListItem) {
          const { form } = target;
          const index = Array.from(form.elements).indexOf(target);
          if (index !== -1 && form.elements[index + 2]) {
            form.elements[index + 2].focus();
            event.preventDefault();
          }
        }
      };
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [location]);

  // disable onwheel action on every number input field
  useEffect(() => {
    const handleWheel = (event) => {
      const { target } = event;
      const isNumberInput = target.type === 'number';
      if (isNumberInput) {
        target.blur();
      }
    };
    document.addEventListener('wheel', handleWheel);
    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);


  return (
    <>
      <Helmet>
        <title>MySale Books: Simplified Accounting, Inventory, & Invoicing</title>
      </Helmet>
      <LocalizationProvider>
        <HeaderProvider>
          <SettingsProvider>
            <ThemeProvider>
              <CssBaseline />
              <AuthGuard>{content}</AuthGuard>
            </ThemeProvider>
          </SettingsProvider>
        </HeaderProvider>
      </LocalizationProvider>
    </>
  );
};
export default App;
